<template>
  <ui-component-modal
    :modalTitle="'Add payment'"
    :isSaving="isSaving"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservationPayment"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped has-text-vertical-middle">
        <tbody>
          <tr>
            <td>Date</td>
            <td>
              <ui-base-date-field
                v-model="paymentDate"
                :openCalendarInModal="true"
                @updateDates="
                  (value) => {
                    paymentDate = value
                  }
                "
              />
            </td>
          </tr>
          <tr>
            <td>Type</td>
            <td>
              <label
                v-for="(pt, index) in paymentTypes"
                :key="pt.id"
                class="radio"
                :class="{ 'ml-4': index !== 0 }"
              >
                <input
                  type="radio"
                  name="paymentType"
                  v-model="paymentType"
                  :value="Number(pt.id)"
                  :checked="paymentType === pt.id"
                />
                {{ $t(`General.PaymentType_${pt.id}`) }}
              </label>
            </td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>
              <div class="field has-addons">
                <p class="control">
                  <a
                    class="button is-static"
                    v-html="reservation.CurrencySymbol"
                  ></a>
                </p>
                <p class="control">
                  <input type="text" class="input" v-model.number="amount" />
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import paymentProvider from '@/providers/payment'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      amount: 0,
      paymentType: 2,
      paymentTypes: [],
      paymentDate: new Date(),
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.amount = this.reservation.TotalOpen

    this.paymentTypes.push({ id: 1, name: 'Cash' })
    this.paymentTypes.push({ id: 2, name: 'Transfer' })
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveReservationPayment() {
      let self = this
      self.isSaving = true

      paymentProvider.methods
        .addReservationPayment(
          self.reservation.Id,
          self.paymentType,
          self.amount,
          this.$options.filters.dateObjectIsoDateString(this.paymentDate)
        )
        .then((response) => {
          if (response.status === 201) {
            self.$emit('paymentAdd', response.data)

            self.reservation.TotalOpen = self.roundToTwo(
              self.reservation.TotalOpen - self.amount
            )
            self.reservation.TotalPaid = self.roundToTwo(
              self.reservation.TotalPaid + self.amount
            )

            // Update reservation store
            self.setReservation(self.reservation)

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Payment added!',
            })

            this.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },
  },
}
</script>
