<template>
  <div>
    <ModalAddPayment
      v-if="showAddPayment"
      :showModal="showAddPayment"
      :onClickCancel="hideModal"
      @paymentAdd="addPayment"
    />

    <ModalDeletePayment
      v-if="showDeletePayment"
      :showModal="showDeletePayment"
      :payment="selectedPayment"
      :onClickCancel="hideModal"
      @paymentDeleted="deletePayment"
    />

    <ui-section-header>
      <template v-slot:title>Payments</template>
    </ui-section-header>

    <div class="columns">
      <div class="column">
        <div class="box">
          <ReservationTotals :showAmountOpen="true" />
        </div>

        <div v-if="paymentLink" class="box has-margin-top">
          <p class="subtitle">Payment link</p>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Amount</th>
                <th></th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    paymentLink.Amount
                      | toCurrency($i18n.locale, paymentLink.Currency)
                  }}
                </td>
                <td></td>
                <td class="has-text-right">
                  <a @click="deletePaymentLink()">
                    <span class="icon has-text-danger">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column">
        <message
          v-if="payments.length === 0"
          :message="'No payments made'"
          class="has-margin-top"
        />

        <table
          v-if="payments.length > 0"
          class="table is-fullwidth is-striped is-narrow has-margin-top"
        >
          <thead>
            <tr>
              <th>Amount</th>
              <th></th>
              <th>Created by</th>
              <th>Date</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in payments" :key="payment.Id">
              <td>
                {{ payment.Amount | toCurrency('en', payment.CurrencyIso) }}
              </td>
              <td>{{ payment.Status }}</td>
              <td>{{ payment.ProfileName }}</td>
              <td>
                {{
                  payment.CreatedOn | dateFormat($i18n.locale, 'longDateFormat')
                }}
              </td>
              <td>
                <a @click="setShowDeletePayment(payment)">
                  <span class="icon ">
                    <font-awesome-icon
                      :icon="['fas', 'trash-alt']"
                      style="color:red"
                    />
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="has-text-right">
          <a @click="setShowAddPayment" class="button is-success">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>Add payment</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import paymentProvider from '@/providers/payment'

import Message from '@/components/UI/Message'
import ReservationTotals from '@/components/Reservations/ReservationTotals'
import ModalAddPayment from '@/components/Reservations/ModalAddPayment'
import ModalDeletePayment from '@/components/Reservations/ModalDeletePayment'

export default {
  components: {
    message: Message,
    ModalAddPayment,
    ModalDeletePayment,
    ReservationTotals,
  },

  props: {},

  data() {
    return {
      selectedPayment: null,
      isLoading: true,
      payments: [],
      showAddPayment: false,
      showDeletePayment: false,
      paymentLink: null,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.getReservationPayments()
    this.getPaymentLink()
  },

  methods: {
    getPaymentLink() {
      paymentProvider.methods
        .getPaymentLink('Reservation', this.reservation.Id)
        .then((response) => {
          if (response.status === 200) {
            this.paymentLink = response.data
          }
        })
    },

    deletePayment(payment) {
      let index = this.payments.findIndex((p) => p.Id === payment.Id)
      if (index > -1) {
        this.payments.splice(index, 1)
      }
    },

    addPayment(payment) {
      let self = this
      self.payments.push(payment)
    },

    getReservationPayments() {
      let self = this
      paymentProvider.methods
        .getReservationPayments(this.reservation.Id)
        .then((response) => {
          self.payments = response.data
        })
        .catch((e) => {})
        .finally(() => {
          self.isLoading = false
        })
    },

    deletePaymentLink() {
      paymentProvider.methods
        .deletePaymentLink(this.paymentLink.Key)
        .then((response) => {
          if (response.status === 204) {
            this.paymentLink = null
          }
        })
    },

    setShowPayments() {
      this.setVisibleItem('payments')
    },

    setShowAddPayment() {
      this.showAddPayment = true
    },

    setShowDeletePayment(payment) {
      this.selectedPayment = payment
      this.showDeletePayment = true
    },

    hideModal() {
      this.showAddPayment = false
      this.showDeletePayment = false

      this.selectedPayment = null
    },
  },
}
</script>
