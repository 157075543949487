import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getPaymentLink(type, itemId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/link`,
        {
          params: {
            type: type,
            itemId: itemId
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationPayments(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deletePayment(paymentId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/payment/${paymentId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async addInvoicePayment(invoiceId, paymentType, amount, date) {
      let criteria = {
        InvoiceId: invoiceId,
        PaymentType: paymentType,
        Amount: amount,
        PaymentDate: date
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/invoice/${invoiceId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async addReservationPayment(reservationId, paymentType, amount, date) {
      let criteria = {
        ReservationId: reservationId,
        PaymentType: paymentType,
        Amount: amount,
        PaymentDate: date
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/reservation/${reservationId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getPaymentDetails(paymentKey) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/${paymentKey}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
          }
        }
      )
      return response
    },

    async startPaymentProcess(paymentKey, returnUrl) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/${paymentKey}/start`,
        returnUrl,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
          }
        }
      )
      return response
    },

    async deletePaymentLink(key) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/payment/link/${key}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
