<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservations.ModalDeletePayment.Main_Title')"
     confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deletePayment"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered"
      v-html="$t('Components.Reservations.ModalDeletePayment.Text_DeletePayment', {paymentAmount: $options.filters.toCurrency(payment.Amount) })">
      </div>
      <div class="has-text-centered">
         <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="$t('Components.Reservations.ModalDeletePayment.Text_YesSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import paymentProvider from '@/providers/payment'
const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox
  },

  props: {
    payment: {
      type: Object,
      default: null
    },

    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation'])
  },

  created() {},

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    deletePayment() {
      let self = this
      self.isDeleting = true

      paymentProvider.methods
        .deletePayment(self.payment.Id)
        .then(response => {
          if (response.status === 204) {
            self.isDeletingSuccess = true
            self.$emit('paymentDeleted', self.payment)

            self.reservation.TotalOpen =
              self.reservation.TotalOpen + self.payment.Amount
            self.reservation.TotalPaid =
              self.reservation.TotalPaid - self.payment.Amount

            // Update reservation store
            self.setReservation(self.reservation)

            // Set timer to close popup in in 1,5 seconds
            setTimeout(() => {
              this.onClickCancel()
            }, 1500)

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Payment deleted!'
            })
          }
        })
        .catch(error => {
          self.isDeletingError = true
        })
        .finally(() => {
          self.isDeleting = false
        })
    }
  }
}
</script>
